.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #DDD8C4;
  width: 40rem;
  height: 20rem;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
  transition: background-image 0.5s ease-in-out;
}

.cardContent {
  position: relative;
  z-index: 2;

  padding: 1rem;
  border-radius: 8px;
}

.card h2 {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
  margin: 0.5em 0;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);

  padding-bottom: 0.3em;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  text-align: center;
}
